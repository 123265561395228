import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CSSPlugin from 'gsap/CSSPlugin';
gsap.registerPlugin(ScrollTrigger, CSSPlugin);

//
import Swiper from 'swiper';
import { Autoplay, Pagination, Scrollbar, Navigation, EffectCoverflow } from 'swiper/modules';
Swiper.use([Autoplay, Pagination, Scrollbar, Navigation, EffectCoverflow]);

window.addEventListener("load", function () {

	const body = document.querySelector("#wrap");
	const loadingDom = document.querySelector(".loading");
	const lodaingLogo = document.querySelectorAll(".loading .logo");
	const lodaingBg = document.querySelectorAll(".--js-lodaingBg");

	const loadingTimerid1 = setTimeout(() => {
		window.scrollTo({
			top: 0,
			behavior: "instant"
		});
		gsap.fromTo(
			lodaingLogo,
			{
				scale: 1.3,
				opacity: 0,
			},
			{
				scale: 1,
				opacity: 1,
				ease: "power4.out",
				duration: .9,
				stagger: {
					each: 0.2,
				}
			}
		);
	}, 200);

	const loadingTimerid2 = setTimeout(() => {
		clearTimeout(loadingTimerid1);
		gsap.to(
			lodaingLogo,
			{
				marginTop: -60,
				opacity: 0,
				ease: "power4.out",
				duration: .3,
			}
		);

		const loadingAnim = gsap.timeline();
		loadingAnim.to(
			lodaingBg,
			{
				height: 0,
				ease: "expo.out",
				duration: .9,
				stagger: {
					each: 0.1,
				}
			}
		);
		loadingAnim.to(
			loadingDom,
			{
				'--clip': '100%',
				ease: "expo.out",
				duration: .9,
			}, '-=.8'
		);
		loadingAnim.fromTo(
			body,
			{
				y: "50vh",
			},
			{
				y: 0,
				ease: "expo.out",
				duration: 1.8,
			}, '-=1.5'
		);
		loadingAnim.add(() => {
			body.style.transform = null;
		});
	}, 2200);

	const spanWrapText = (target) => {
		const nodes = [...target.childNodes];
		let returnText = '';

		for (const node of nodes) {
			if (node.nodeType == 3) {
				const text = node.textContent.replace(/\r?\n/g, '');
				const splitText = text.split('');
				for (const char of splitText) {
					returnText += `<i>${char}</i>`;
				}
			} else {
				returnText += node.outerHTML;
			}
		}
		return returnText;
	};
	const bubblesTop = [...document.querySelectorAll('.--js-anim-top')];
	for (const bubbleTop of bubblesTop) {

		const innersTop = bubbleTop.querySelectorAll(".--js-anim-dom__inner");

		for (const innerTop of innersTop) {
			innerTop.innerHTML = spanWrapText(innerTop);
		}
	}


	const bubbles = [...document.querySelectorAll('.--js-anim-dom')];
	for (const bubble of bubbles) {

		const inners = bubble.querySelectorAll(".--js-anim-dom__inner");

		for (const inner of inners) {
			inner.innerHTML = spanWrapText(inner);
		}
	}

	const loadingTimerid3 = setTimeout(() => {

		//
		clearTimeout(loadingTimerid2);

		//
		for (const bubbleTop of bubblesTop) {

			const innersTop = bubbleTop.querySelectorAll(".--js-anim-dom__inner");

			bubbleTop.spans = bubbleTop.querySelectorAll('i');

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: bubbleTop,
					start: 'top-=140px bottom',
					//markers: true,
				},
			});

			tl.from(bubbleTop, {
				//opacity: 0,
				//y: '10%',
			}).to(bubbleTop.spans, {
				y: 0,
				ease: "expo.out",
				duration: .86,
				stagger: 0.018,
			}
			);
		}

	}, 2300);

	const loadingTimerid4 = setTimeout(() => {

		//
		clearTimeout(loadingTimerid3);


		//
		const heroWrap = document.querySelector('.hero');
		const heroBg = heroWrap.querySelector(".hero__bg");

		/*gsap.fromTo(heroWrap,
			{
				yPercent:0,
			},
			{
				yPercent:-10,

				scrollTrigger: {
					trigger: heroWrap,
					start: "top+=2px top",
					ease: "none",
					scrub: 1,

				},
			}
		);*/
		gsap.fromTo(heroBg,
			{
				scale: 1,
			},
			{
				scale: 1.06,

				scrollTrigger: {
					trigger: heroWrap,
					start: "top+=2px top",
					ease: "none",
					scrub: 1,

				},
			}
		);

		//
		const conceptWrap = document.querySelector('.concept');
		const conceptPic1 = conceptWrap.querySelector(".concept__pic1");
		const conceptPic1__img = conceptPic1.querySelector("img");
		const conceptPic2 = conceptWrap.querySelector(".concept__pic2");
		const conceptPic2__img = conceptPic2.querySelector("img");

		gsap.fromTo(conceptPic1,
			{
				yPercent: 10,
			},
			{
				yPercent: -10,

				scrollTrigger: {
					trigger: conceptWrap,
					start: "top bottom",
					ease: "none",
					scrub: 1,
				},
			}
		);
		gsap.fromTo(conceptPic1__img,
			{
				yPercent: -5,
				scale: 1.1,
			},
			{
				scale: 1.1,
				yPercent: 5,

				scrollTrigger: {
					trigger: conceptWrap,
					start: "top bottom",
					ease: "none",
					scrub: 1,
				},
			}
		);
		gsap.fromTo(conceptPic2,
			{
				yPercent: -50,
			},
			{
				yPercent: 20,

				scrollTrigger: {
					trigger: conceptWrap,
					start: "top bottom",
					ease: "none",
					scrub: 1,
				},
			}
		);
		gsap.fromTo(conceptPic2__img,
			{
				yPercent: -20,
				scale: 1.4,
			},
			{
				scale: 1.4,
				yPercent: 20,

				scrollTrigger: {
					trigger: conceptWrap,
					start: "top bottom",
					ease: "none",
					scrub: 1,
				},
			}
		);



		const itemWrapper = document.querySelector('.collection__fixed');
		const itemInner = document.querySelector('.collection__list');

		gsap.to(itemInner, {
			x: () => -(itemInner.clientWidth - itemWrapper.clientWidth),
			ease: 'none',
			scrollTrigger: {
				trigger: itemWrapper,
				start: 'center center',
				end: 'top top',
				end: () => `+=${itemInner.clientWidth - itemWrapper.clientWidth}`,
				//markers: true,
				scrub: true,
				pin: true,
				invalidateOnRefresh: true,
				anticipatePin: 1,
			},
		});

		//
		const newsWrap = document.querySelector('.news');
		const newsPic = newsWrap.querySelector(".concept__pic img");
		gsap.fromTo(newsPic,
			{
				scale: 1.12,
			},
			{
				scale: 1,

				scrollTrigger: {
					trigger: newsWrap,
					start: "top bottom",
					ease: "none",
					scrub: 1,

				},
			}
		);

		//
		const aboutWrap = document.querySelector('.about__bottom');
		const aboutPic = aboutWrap.querySelector(".concept__pic img");
		gsap.fromTo(aboutPic,
			{
				scale: 1,
			},
			{
				scale: 1.12,

				scrollTrigger: {
					trigger: aboutWrap,
					start: "top bottom",
					ease: "none",
					scrub: 1,

				},
			}
		);



		//
		const storesSliderPrev = document.querySelector('.stores__btn--prev');
		const storesSliderNext = document.querySelector('.stores__btn--next');

		let swiperParam;

		if (window.innerWidth < 640) {
			swiperParam = {
				//effect: 'slide',
				loop: true,
				centeredSlides: true,
				slidesPerView: "auto",
				spaceBetween: 0,
				speed: 400,
				effect: "coverflow",
				parallax: true,
				initialSlide: 1,
				coverflowEffect: {
					rotate: 0,
					stretch: -50,
					depth: 120,
					modifier: 1,
					slideShadows: false,
				},

				watchSlidesProgress: false,
			}
		} else {
			swiperParam = {
				//effect: 'slide',
				loop: true,
				centeredSlides: true,
				slidesPerView: "auto",
				spaceBetween: 0,
				speed: 400,
				effect: "coverflow",
				parallax: true,
				initialSlide: 1,
				coverflowEffect: {
					rotate: 0,
					stretch: -160,
					depth: 200,
					modifier: 1,
					slideShadows: false,
				},

				watchSlidesProgress: false,
			}
		}
		const storesSlider = new Swiper('.stores__list', swiperParam);

		storesSliderPrev.addEventListener("click", function () {
			storesSlider.slidePrev();
		});
		storesSliderNext.addEventListener("click", function () {
			storesSlider.slideNext();
		});


		storesSlider.slidePrev();

		//
		for (const bubble of bubbles) {

			const inners = bubble.querySelectorAll(".--js-anim-dom__inner");

			bubble.spans = bubble.querySelectorAll('i');

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: bubble,
					start: 'top-=140px bottom',
					//markers: true,
				},
			});

			tl.from(bubble, {
				//opacity: 0,
				//y: '10%',
			}).to(bubble.spans, {
				y: 0,
				ease: "expo.out",
				duration: .86,
				stagger: 0.018,
			}
			);
		}


		ScrollTrigger.refresh;
	}, 3000);


});
